import React from 'react';
import { Link } from 'react-router-dom';
import mirror from './imgs/jumper.png';
import './index.css';
import LorenzAttractor from './LorenzAttractor';
import GrainBackdrop from './GrainBackdrop';
import Header from './Header';
import Background from './Background';



function Partners() {
  const arr0 = ['#C0FDFB', '#64B6AC', '#FAD4C0', '#FEE9E1', '#B09E99'];
  const arr1 = ['#F9D689', '#FEFFD2', '#FFBF78', '#FFEEA9', '#F0A500'];
  const arr2 = ['#FEA1A1', '#ECCDB4', '#F0EDD4', '#F9FBE7', '#FF7D29'];
  const arr3 = ['#fff5d7', '#ECCDB4', '#ECCDB4', '#ECCDB4', '#ECCDB4'];
  const arr4 = ['#E0BFB8', '#F8C8DC', '#F3CFC6', '#C9A9A6', '#F2D2BD'];

  return (
    <div className="relative">
      <div className="fixed top-0 left-0 w-full h-full z-0">
        <Background />
      </div>
      <div className="relative z-10 text-center">
        <header className="min-h-screen flex flex-col  text-white">
          <Header />

          <p className='mt-[150px] md:mt-[200px] text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl font-helixa-black font-bold'>

          </p>


          <div className='w-full max-w-[600px] pl-5 mx-auto text-left mt-5 text-black text-2xl'>

            {/* <img src={mirror} className="w-[300px] pointer-events-none mr-10 mt-[-140px] grayscale-0" /> */}
            {/* <p className=' text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl'>We are all built <br /><span className='font-bold'>unique</span>.</p>
            <br /> */}
            <p className=' text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl'>Welcome to <br /><span className='font-bold'> 3D sizing</span>.</p>
            <br />
            <p className=' text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl'>Shop using your <br /><span className='font-bold'> shape</span>.</p>
            <br />
            <p className=' text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl'>Dare brands to <br /><span className='font-bold'> end mass production</span>.</p>
            <br />


            <div className='flex space-x-4 mt-8  md:mt-12 text-xl sm:text-2xl'>
              <Link to="/scan" className="relative btn-slide-show font-helixa-regular px-10 md:px-20">
                <p className="btn-slide-show-text1">Let's go!</p>
                <div className="btn-slide-show-inner">
                  <p className="btn-slide-show-text2">Start →</p>
                </div>
              </Link>
            </div>
          </div>
        </header>
      </div >
    </div >
  );
}

export default Partners;