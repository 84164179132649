import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Scan from './Scan';
import ScanR from './ScanR';
import Partners from './Partners';
import LinkPhone from './LinkPhone';
import Body from './Body';
import TestQuery from './TestQuery';
import { useState } from 'react';

function AppRouter() {

  const [localScan, setLocalScan] = useState("");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ScanR setLocalScan={setLocalScan} />} />
        <Route path="/link" element={<LinkPhone />} />
        <Route path="/scan" element={<Scan />} />
        <Route path='/body' element={<Body localScan={localScan} />} />
        <Route path='/test' element={<TestQuery />} />
        <Route path="/about" element={<Partners />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;