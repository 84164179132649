import React, { useEffect, useState } from "react";
import side_view from "./imgs/test_photos/test.png";
import front_view from "./imgs/test_photos/test.png";

const TestQuery = () => {
  const [frontImgBase64, setFrontImgBase64] = useState(null);
  const [sideImgBase64, setSideImgBase64] = useState(null);

  const [responseHtml, setResponseHtml] = useState("");

  // Function to convert image file to Base64
  const convertToBase64 = (imagePath, setImageState) => {
    fetch(imagePath)
      .then((response) => response.blob()) // Convert image to blob
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1]; // Remove data URL prefix
          setImageState(base64String);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error converting image to Base64:", error));
  };

  useEffect(() => {
    convertToBase64(front_view, setFrontImgBase64);
    convertToBase64(side_view, setSideImgBase64);
  }, []);

  const getTest = async () => {
    if (!frontImgBase64 || !sideImgBase64) {
      console.error("Images not loaded yet.");
      return;
    }

    try {
      console.log(frontImgBase64)
      const payload = {
        height: "1.0",
        images: [
          { data: frontImgBase64, fileName: "front.png" },
          { data: sideImgBase64, fileName: "side.png" },
        ],
      };

      const response = await fetch("https://api.snapmeasureai.com/smpl/v2/analyze", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      const result = await response.json();
      console.log("Body Scan Results:", result);
      setResponseHtml(result.html);
    } catch (error) {
      console.error("Error during body scan:", error);
    }
  };

  return (
    <div>
      <button onClick={getTest} disabled={!frontImgBase64 || !sideImgBase64}>
        Run Test Query
      </button>

      {/* Render returned HTML safely */}
      {responseHtml && (
        <div
          dangerouslySetInnerHTML={{ __html: responseHtml }}
          className="response-container"
        />
      )}
    </div>
  );
};

export default TestQuery;
